import * as React from "react";
import Avatar from "@mui/joy/Avatar";
import Chip from "@mui/joy/Chip";
import Box from "@mui/joy/Box";
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import Typography from "@mui/joy/Typography";
import { Rating } from "@mui/material";

const cardData = [
  {
    id: 1,
    name: "Stephen S",
    text: "Sheizka has been extremely helpful and supportive in helping me with identifying the best insurance solution for my needs. I would recommend her and Exact Medicare to anyone. ",
    rating: 5,
  },
  {
    id: 2,
    name: "Mary B",
    text: "Sheizka is delightful person. She went the extra mile to make sure I was completely taken care of",
    rating: 5,
  },
  {
    id: 3,
    name: "Constance C",
    text: "Sheizka provided information on medicare to make an informed decision on which plan would be best for me. I know her personally and of course, she was professional in our meeting to discuss coverage.",
    rating: 5,
  },
];

function BioCard({ card }) {
  return (
    <Card
      variant=""
      sx={{
        width: 320,
        height: 320,
        maxWidth: "100%",
        boxShadow: "lg",
        opacity: 1,
        margin: "1rem",
        transition: "opacity 1s",
      }}
    >
      <CardContent sx={{ alignItems: "center", textAlign: "center" }}>
        <Avatar sx={{ "--Avatar-size": "4rem" }} />
        <Chip
          size="sm"
          variant="soft"
          color="warning"
          sx={{
            mt: -1,
            mb: 1,
            border: "3px solid",
            borderColor: "background.surface",
            letterSpacing: "1px",
            fontFamily: "Lato, sans-serif",
          }}
        >
          CLIENT
        </Chip>
        <Typography
          sx={{ fontFamily: "Lato, sans-serif", letterSpacing: "1px" }}
          level="title-lg"
        >
          {card.name}
        </Typography>
        <Typography
          level="body-sm"
          sx={{
            maxWidth: "28ch",
            fontSize: "0.9rem",
            fontFamily: "Lato, sans-serif",
          }}
        >
          {card.text}
        </Typography>
        <Box
          sx={{
            display: "flex",
            gap: 2,
            mt: 2,
          }}
        >
          <Rating readOnly sx={{ marginTop: "1rem" }} value={card.rating} />
        </Box>
      </CardContent>
    </Card>
  );
}

export default function BioCardCarousel() {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "column", md: "row" }, // Column on mobile, row on large screens
        justifyContent: "center",
        flexWrap: "wrap",
        gap: 2,
        width: "100%",
      }}
    >
      {cardData.map((card) => (
        <BioCard key={card.id} card={card} />
      ))}
    </Box>
  );
}
