import React, { useRef } from "react";
import {
  Box,
  Card,
  CardActions,
  CardContent,
  Chip,
  Typography,
} from "@mui/joy";
import { motion, useInView } from "framer-motion";
import CardButton from "./CardButton";


const WhatWeDo = () => {
  const reviewReasons = [
    {
      id: 1,
      name: "Evaluate Plan Changes",
      desc: "Medicare plans change annually. Reviewing during AEP keeps you informed of any changes to your plan’s coverage, premiums, or provider network.",
      chip: "Plan updates",
    },
    {
      id: 2,
      name: "Adjust to Health Needs",
      desc: "As your health needs change, different coverage might be necessary. AEP is your chance to ensure your plan still fits your health requirements.",
      chip: "Health needs",
    },
    {
      id: 3,
      name: "Prescription Coverage",
      desc: "Part D drug plans update formularies yearly. Reviewing ensures your meds are covered at the best price. Expect changes with a $2,000 cap in 2025.",
      chip: "Rx coverage",
    },
    {
      id: 4,
      name: "Explore New Options",
      desc: "New Medicare Advantage or Part D plans might be available in your area, offering better coverage or reduced costs.",
      chip: "Better options",
    },
    {
      id: 5,
      name: "Improve Benefits",
      desc: "Some plans offer additional benefits like dental, vision, hearing, or wellness programs, potentially more valuable than your current plan.",
      chip: "Added perks",
    },
    {
      id: 6,
      name: "Cost Savings",
      desc: "A review can uncover ways to save on premiums, copays, and other out-of-pocket expenses.",
      chip: "Save money",
    },
    {
      id: 7,
      name: "Provider Networks",
      desc: "Ensure your preferred doctors and healthcare providers remain in-network with your plan.",
      chip: "Doctors",
    },
    {
      id: 8,
      name: "Financial Changes",
      desc: "If your financial situation changes, you may qualify for different plans or additional assistance programs.",
      chip: "Stay informed",
    },
    {
      id: 9,
      name: "Peace of Mind",
      desc: "A thorough review provides peace of mind, ensuring you have the best coverage for the upcoming year.",
      chip: "Worry less",
    },
  ];

  const ref = useRef(null);
  const inView = useInView(ref, { triggerOnce: true });

  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0 }}
      animate={{ opacity: inView ? 2 : 0 }}
      transition={{ duration: 2 }}
    >
      <div>
        <div style={{}}>
          <Box>
            <Typography
              sx={{
                marginTop: "1.5rem",
                color: "#218372",
                fontSize: { xs: "1.7rem", md: "2rem" },
                letterSpacing: "1.2px",
                textAlign: "center",
                padding: "5px",
                fontFamily: "Lato, sans-serif",
                fontWeight: 800,
              }}
            >
              Why should you review your policy?
            </Typography>
            <Typography
              level="body-sm"
              sx={{
                fontSize: "1.2rem",
                letterSpacing: "1px",
                textAlign: "center",
                padding: "5px",
                fontFamily: "Lato, sans-serif",
              }}
            >
              Here are several reasons why you will want to do a Medicare review
              this AEP (Annual Enrollment)
            </Typography>
          </Box>

          <Box
            sx={{
              marginTop: "1rem",
              padding: "10px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-around",
              flexWrap: "wrap",
            }}
          >
            {reviewReasons.map((reason) => (
              <Card
                key={reason.id}
                variant=""
                color=""
                invertedColors
                sx={{
                  boxShadow: "lg",
                  bgcolor: "#218372",
                  width: 400,
                  maxWidth: "100%",
                  overflow: "auto",
                  margin: "1rem",
                  textAlign: "center",
                  position: "relative",
                }}
              >
                <Box
                  sx={{
                    position: "absolute",
                    top: 8,
                    left: 8,
                    zIndex: 1,
                  }}
                >
                  <Chip
                    sx={{
                      bgcolor: "#E0AE73",
                      color: "white",
                      letterSpacing: "1.2px",
                    }}
                    size="md"
                    variant="soft"
                  >
                    {reason.chip}
                  </Chip>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    marginBottom: "1rem",
                  }}
                ></Box>
                <CardContent>
                  <Typography
                    level="h2"
                    sx={{
                      marginTop: "1.5rem",
                      color: "white",
                      letterSpacing: "1px",
                      fontFamily: "Lato, sans-serif",
                    }}
                  >
                    {reason.name}
                  </Typography>
                  <Typography
                    level="body-sm"
                    sx={{
                      fontSize: "1rem",
                      color: "white",
                      marginTop: "1rem",
                      fontFamily: "Lato, sans-serif",
                    }}
                  >
                    {reason.desc}
                  </Typography>
                </CardContent>
                <CardActions sx={{ justifyContent: "center" }}>
                  <CardButton />
                </CardActions>
              </Card>
            ))}
          </Box>
        </div>
      </div>
    </motion.div>
  );
};

export default WhatWeDo;
