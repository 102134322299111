import { Box, Grid, Typography } from "@mui/joy";
import React from "react";
import mutual from "../../images/mutual.png";
import usmap from "../../images/UsMap.png";

const Partners = () => {
  const partners = [
    "https://images.leadconnectorhq.com/image/f_webp/q_80/r_1200/u_https://assets.cdn.filesafe.space/ThQ6LuzgQgXIWKUDsvmH/media/660d690be463053892847a1f.png",
    "https://images.leadconnectorhq.com/image/f_webp/q_80/r_1200/u_https://assets.cdn.filesafe.space/ThQ6LuzgQgXIWKUDsvmH/media/660d6957e56c798442fb9dd0.png",
    "https://images.leadconnectorhq.com/image/f_webp/q_80/r_1200/u_https://assets.cdn.filesafe.space/ThQ6LuzgQgXIWKUDsvmH/media/660d6968d000cbae39bc8810.png",
    "https://images.leadconnectorhq.com/image/f_webp/q_80/r_1200/u_https://assets.cdn.filesafe.space/ThQ6LuzgQgXIWKUDsvmH/media/660d69753b744d816cb02b3a.png",
    "https://images.leadconnectorhq.com/image/f_webp/q_80/r_1200/u_https://assets.cdn.filesafe.space/ThQ6LuzgQgXIWKUDsvmH/media/660d6985ac93d30ec05fa3d6.png",
    "https://images.leadconnectorhq.com/image/f_webp/q_80/r_1200/u_https://assets.cdn.filesafe.space/ThQ6LuzgQgXIWKUDsvmH/media/660d69946f76b8200a31db8d.png",
    "https://www.healthmarkets.com/wp-content/uploads/2019/10/DevotedHealth2-768x96.png",
    "https://www.seniorliving.org/app/uploads/2023/06/wellcare-logo.png",
    `${mutual}`,
  ];

  return (
    <div>
      <Typography
        level="h2"
        sx={{
          fontSize: "2.5rem",
          padding: "10px",
          marginTop: "2rem",
          letterSpacing: "1px",
          color: "#218372",
          fontFamily: "Lato, sans-serif",
          textAlign: "center",
          fontWeight: 800,
        }}
      >
        Our Trusted Partners
      </Typography>

      <Grid
        container
        spacing={5}
        sx={{ width: "100%", textAlign: "center", marginTop: "2rem" }}
      >
        {partners.map((partner, index) => (
          <Grid xs={12} sm={6} md={4} key={index.toString()}>
            <img
              src={partner}
              alt={`Partner ${index + 1}`}
              style={{
                width: "100%",
                maxWidth: "270px",
                height: "auto",
                display: "block",
                margin: "0 auto",
              }}
            />
          </Grid>
        ))}
      </Grid>
      <div style={{textAlign: 'center', marginTop: '4rem', padding: '10px'}}>
        <Typography level="title-lg" sx={{fontSize: {xs: '1rem', md: '1.3rem'}, letterSpacing: '1.2px'}} >Coverage in 49 States</Typography>
      </div>

      <div style={{ textAlign: "center", padding: "10px" }}>
        <Box 
        component="img"
          src={usmap}
          alt="states"
          sx={{
            width: {xs: '80%', md: '50%'}
          }}
        />
      </div>
    </div>
  );
};

export default Partners;
