import React, { useState } from "react";
import {
  Button,
  Modal,
  Box,
  Input,
  Select,
  Option,
  Typography,
  RadioGroup,
  Radio,
  Tooltip,
  Link,
  Snackbar,
} from "@mui/joy";
import {
  AddReaction,
  CalendarMonth,
  East,
  Info,
  Mail,
  Person,
  Phone,
  PlaylistAddCheckCircle,
} from "@mui/icons-material";
import axios from "axios";
import { FormControlLabel } from "@mui/material";

const ScheduleButton = () => {
  const [open, setOpen] = useState(false);
  const [iframeOpen, setIframeOpen] = useState(false);
  const [openSnackBar, setOpenSnackBar] = useState(false);

  const [formData, setFormData] = useState({
    phoneNumber: "",
    referredBy: "",
    advisorName: "",
    firstName: "",
    lastName: "",
    email: "",
    meetingType: "",
    meetingLocation: "",
    otherLocation: "",
    agreedSMS: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleReferredChange = (value) =>
    setFormData((prevState) => ({
      ...prevState,
      referredBy: value,
    }));

  const handleSubmit = async () => {
    try {
      await axios.post(
        "https://services.leadconnectorhq.com/hooks/ThQ6LuzgQgXIWKUDsvmH/webhook-trigger/148d498d-6d98-4144-8c90-a8c4165ad90f",
        formData
      );

      const scheduleURL = new URL(
        "https://calendar.google.com/calendar/appointments/schedules/AcZssZ282beL-JfOGUOup5StdZcd-AF9EQwYDBywviJrIlGNOdYcRSyIz_ZO-_CD5Q5lsGWXCO6SxItN?gv=true"
      );
      scheduleURL.searchParams.set(
        "name",
        `${formData.firstName} ${formData.lastName}`
      );
      scheduleURL.searchParams.set("email", formData.email);
      scheduleURL.searchParams.set("phone", formData.phoneNumber);
      scheduleURL.searchParams.set("meetingType", formData.meetingType);
      setOpenSnackBar(true);

      setTimeout(() => {
        setIframeOpen(true);
      }, 3000);

      setIframeURL(scheduleURL.toString());
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleIframeClose = () => setIframeOpen(false);

  const [iframeURL, setIframeURL] = useState("");

  const isFormValid =
    formData.firstName &&
    formData.phoneNumber &&
    formData.email &&
    formData.meetingType &&
    formData.agreedSMS &&
    formData.referredBy;

  const asterisks = <span style={{ color: "red" }}>*</span>;

  return (
    <div>
      <div style={{ marginTop: "2rem", marginRight: "1.5rem" }}>
        <Button
          variant="soft"
          sx={{
            bgcolor: "white",
            color: "#218372",
            fontSize: "1.1rem",
            transition: "background-color 0.3s ease",
            fontFamily: "Lato, sans-serif",
            "&:hover": {
              bgcolor: "#E0AE73",
              color: "white",
            },
          }}
          endDecorator={<CalendarMonth />}
          onClick={() => setOpen(true)}
        >
          Get 1-on-1 assistance
        </Button>
      </div>

      <Modal
        sx={{ marginTop: { xs: "1.1rem", md: "1.8rem" }, width: "100%" }}
        open={open}
        onClose={() => setOpen(false)}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: 2,
            backgroundColor: "white",
            borderRadius: 5,
            boxShadow: 3,
            width: "80%",
            maxWidth: { xs: 330, md: 500 },
            margin: "auto",
            maxHeight: "83vh",
            overflowY: "auto",
          }}
        >
          <Typography
            level="body-sm"
            sx={{
              marginBottom: 2,
              fontSize: "1.2rem",
              letterSpacing: ".5px",
              fontFamily: "Lato, sans-serif",
            }}
          >
            Let's get started
          </Typography>

          <Box sx={{ width: "100%", marginBottom: 1 }}>
            <Typography
              level="body-sm"
              sx={{
                marginBottom: 1,
                fontSize: "16px",
                letterSpacing: "1px",
                textAlign: "left",
                fontFamily: "Lato, sans-serif",
              }}
            >
              How did you hear about us? {asterisks}
            </Typography>
            <Select
              name="referredBy"
              value={formData.referredBy}
              onChange={(e, value) => handleReferredChange(value)}
              placeholder="Select an option"
              defaultValue=""
              sx={{
                width: "70%",
                marginBottom: 2,
                fontSize: "14px",
              }}
            >
              <Option value="Friends or Family">
                Referred by Friends/Family
              </Option>
              <Option value="Financial Advisor">
                Referred by Financial Advisor
              </Option>
              <Option value="Postcard">Postcard</Option>
              <Option value="Website">Website</Option>
            </Select>

            {formData.referredBy === "Financial Advisor" && (
              <Input
                placeholder="Name of Advisor"
                startDecorator={<AddReaction />}
                name="advisorName"
                value={formData.advisorName}
                onChange={handleChange}
                sx={{
                  width: "70%",
                  fontSize: "14px",
                }}
              />
            )}

            <Typography
              level="body-sm"
              sx={{
                marginTop: "10px",
                fontSize: "16px",
                letterSpacing: "1px",
                textAlign: "left",
                fontFamily: "Lato, sans-serif",
              }}
            >
              Your Information {asterisks}
            </Typography>
          </Box>

          <Box
            sx={{
              width: "100%",
              marginBottom: 1,
              display: "flex",
              justifyContent: "center",
              gap: 1,
            }}
          >
            <Input
              name="firstName"
              placeholder="First name *"
              startDecorator={<Person />}
              value={formData.firstName}
              onChange={handleChange}
              sx={{
                width: "55%",
                fontSize: "14px",
              }}
            />
            <Input
              name="lastName"
              placeholder="Last name *"
              startDecorator={<Person />}
              value={formData.lastName}
              onChange={handleChange}
              sx={{
                width: "55%",
                fontSize: "14px",
              }}
            />
          </Box>

          <Box
            sx={{
              width: "100%",
              marginBottom: 1,
              display: "flex",
              justifyContent: "center",
              gap: 1,
            }}
          >
            <Input
              name="email"
              placeholder="Email *"
              startDecorator={<Mail />}
              value={formData.email}
              onChange={handleChange}
              sx={{
                width: "55%",
                fontSize: "14px",
              }}
            />
            <Input
              name="phoneNumber"
              placeholder="Phone number *"
              type="tel"
              startDecorator={<Phone />}
              value={formData.phoneNumber}
              onChange={handleChange}
              sx={{
                width: "55%",
                fontSize: "14px",
              }}
            />
          </Box>

          <Box sx={{ width: "100%", marginBottom: 2 }}>
            <Typography
              level="body-sm"
              sx={{
                marginBottom: 1,
                textAlign: "left",
                fontSize: "16px",
                letterSpacing: "1px",
                fontFamily: "Lato, sans-serif",
              }}
            >
              Preferred meeting type {asterisks}
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "1rem",
                padding: "1rem",
                bgcolor: "#f0f0f0",
                borderRadius: "8px",
                flexDirection: "column",
                gap: "10px",
              }}
            >
              <RadioGroup
                name="meetingType"
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: { xs: 2, md: 3 },
                }}
                onChange={handleChange}
                value={formData.meetingType}
              >
                {["In person", "Phone call", "Online"].map((label) => (
                  <FormControlLabel
                    key={label}
                    value={label}
                    control={<Radio />}
                    label={
                      <Typography
                        sx={{
                          fontSize: { xs: "12px", md: "1rem" },
                          fontFamily: "Lato, sans-serif",
                        }}
                      >
                        {label}
                      </Typography>
                    }
                  />
                ))}
              </RadioGroup>
            </Box>

            {formData.meetingType === "In person" && (
              <Box>
                <Typography
                  sx={{
                    marginBottom: 1,
                    textAlign: "left",
                    fontSize: "16px",
                    letterSpacing: "1px",
                    fontFamily: "Lato, sans-serif",
                    marginTop: "0.5rem",
                    padding: "2px",
                  }}
                >
                  Please select preferred location {asterisks}
                </Typography>
                <Select
                  name="meetingLocation"
                  value={formData.meetingLocation}
                  onChange={(e, value) =>
                    setFormData((prevState) => ({
                      ...prevState,
                      meetingLocation: value,
                    }))
                  }
                  placeholder="Select an option"
                  defaultValue=""
                  sx={{
                    width: "70%",
                    marginBottom: 2,
                    fontSize: "14px",
                  }}
                >
                  <Option value="Westerville Office">Westerville Office</Option>
                  <Option value="Other">Other</Option>
                </Select>

                {formData.meetingLocation === "Westerville Office" && (
                  <Typography>
                    Address:{" "}
                    <Link
                      href="https://www.google.com/maps/place/Exact+Medicare-+Medicare+Plans/@40.139408,-82.9258676,16z/data=!4m15!1m8!3m7!1s0x8838f59928478c95:0xd36861722a441dce!2s103+Commerce+Park+Dr+%23+103B,+Westerville,+OH+43082!3b1!8m2!3d40.139408!4d-82.9258676!16s%2Fg%2F11nsw82hy1!3m5!1s0x8838f5992915a879:0x6308e4c93b8fbff1!8m2!3d40.139408!4d-82.9258676!16s%2Fg%2F1tds7c42?entry=ttu&g_ep=EgoyMDI0MDgyMS4wIKXMDSoASAFQAw%3D%3D"
                      target="_blank"
                    >
                      103 Commerce Park Dr # 103B, Westerville, OH 43082
                    </Link>
                  </Typography>
                )}

                {formData.meetingLocation === "Other" && (
                  <Box>
                    <Typography
                      sx={{
                        marginBottom: 1,
                        textAlign: "left",
                        fontSize: "16px",
                        letterSpacing: "1px",
                        fontFamily: "Lato, sans-serif",
                        marginTop: "0.5rem",
                        padding: "2px",
                      }}
                    >
                      Where would you like to meet?
                    </Typography>
                    <Input
                      name="otherLocation"
                      onChange={handleChange}
                      startDecorator={<Info />}
                      sx={{ width: "70%" }}
                      value={formData.otherLocation}
                    />
                  </Box>
                )}
              </Box>
            )}

            <Box sx={{ marginTop: "1rem", padding: "10px" }}>
              <Typography
                level="body-sm"
                sx={{
                  marginBottom: 1,
                  fontSize: "16px",
                  letterSpacing: "1px",
                  textAlign: "left",
                  fontFamily: "Lato, sans-serif",
                }}
              >
                Are you okay with receiving SMS? {asterisks}
              </Typography>
              <RadioGroup
                name="agreedSMS"
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: { xs: 2, md: 3 },
                  marginLeft: "1rem",
                  marginTop: "5px",
                }}
                value={formData.agreedSMS}
                onChange={handleChange}
              >
                <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="no" control={<Radio />} label="No" />
              </RadioGroup>
            </Box>
          </Box>

          <Tooltip
            title={
              !isFormValid ? "Please fill out all required fields" : "Submit"
            }
          >
            <span>
              <Button
                endDecorator={
                  <East sx={{ fontSize: "1.2rem", marginTop: "3px" }} />
                }
                variant="plain"
                sx={{
                  marginTop: 2,
                  marginLeft: "2rem",
                  fontSize: "18px",
                  padding: "5px 20px",
                  textTransform: "capitalize",
                  fontFamily: "Lato, sans-serif",
                }}
                onClick={handleSubmit}
                disabled={!isFormValid}
              >
                Select Date & Time
              </Button>
              <Snackbar
                size="lg"
                variant="soft"
                color="success"
                open={openSnackBar}
                onClose={() => setOpenSnackBar(false)}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                startDecorator={<PlaylistAddCheckCircle />}
              >
                Please book appointment & confirm your information
              </Snackbar>
            </span>
          </Tooltip>
        </Box>
      </Modal>

      <Modal open={iframeOpen} onClose={handleIframeClose}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: 2,
            backgroundColor: "white",
            borderRadius: 1,
            boxShadow: 3,
            width: "90%",
            maxWidth: 800,
            margin: "auto",
            marginTop: "3rem",
          }}
        >
          <iframe
            src={iframeURL}
            title="Scheduling"
            style={{
              width: "100%",
              height: "600px",
              border: "none",
              borderRadius: "10px",
            }}
          ></iframe>
        </Box>
      </Modal>
    </div>
  );
};

export default ScheduleButton;
