import { Box, Rating, Typography } from "@mui/material";
import React from "react";
import SwiperCard from "../Home/SwiperCard";
import ScheduleButton from "./ScheduleButton";

const GoogleReviews = () => {
  return (
    <Box
      sx={{ backgroundColor: "#218372", marginTop: "2.4rem", padding: "2rem" }}
    >
      <Box sx={{ textAlign: "center", mb: 4 }}>
        <Typography
          variant="h2"
          sx={{
            fontSize: { xs: "2rem", md: "2rem" },
            fontWeight: "bold",
            color: "white",
            letterSpacing: "1px",
            mb: 2,
            fontFamily: "Lato, sans-serif",
          }}
        >
          See what our clients are saying. <br />{" "}
          <Typography
            level="body-sm"
            sx={{
              fontSize: "1.2rem",
              letterSpacing: "1px",
              textAlign: "center",
              padding: "5px",
              fontFamily: "Lato, sans-serif",
            }}
          >
            More than 2,000 five-star reviews on Google
          </Typography>
        </Typography>
        <Rating readOnly sx={{ fontSize: "3rem" }} value={5} />
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          gap: 2,
        }}
      >
        <Box sx={{}}>
          <SwiperCard />
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              marginLeft: "1rem",
            }}
          >
            <ScheduleButton />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default GoogleReviews;
