import { Box, Typography } from "@mui/joy";
import React from "react";
import { motion } from "framer-motion";
import HeaderButton from "./HeaderButton";
import hero from "../../images/hero.jpeg";
import HeaderList from "./HeaderList";

const Header = () => {
  return (
    <div>
      <Box
        sx={{
          marginLeft: { xs: "1rem", md: "5rem" },
          marginTop: "1rem",
          padding: "10px",
        }}
      >
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <Typography
            sx={{
              letterSpacing: "1.5px",
              color: "#218372",
              fontSize: "2.5rem",
              fontFamily: "Lato, sans-serif",
              fontWeight: 800,
              textAlign: { xs: "center", md: "left" },
            }}
            level="h1"
          >
            Ready for <Typography sx={{ fontStyle: "italic"}}>Annual Enrollment</Typography>?{" "}
            <Typography sx={{ color: "#6e6e73", fontSize: "1.6rem" }}>
              {" "}
              <br />
              Get expert guidance and book a meeting today
            </Typography>
          </Typography>
        </motion.div>
        <div style={{ padding: "8px" }}>
          <motion.div
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
          ></motion.div>

          <motion.div
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5, delay: 0.4 }}
          >
            <Box sx={{ textAlign: { xs: "center", md: "left" } }}>
              <HeaderButton />
            </Box>
          </motion.div>

          <br />
        </div>
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <Box sx={{ padding: "2px" }}>
            <HeaderList />
          </Box>
        </motion.div>

        <Box
          sx={{
            display: "flex",
            justifyContent: { xs: "center", md: "flex-end" },
            alignItems: "center",
            height: { xs: "30vh", md: "1vh" },
            padding: { xs: "0", sm: "0", md: "0 50px" },
            textAlign: { xs: "center", sm: "center", md: "right" },
            marginRight: { xs: "2.2rem", md: "6rem", lg: "10rem" },
          }}
        >
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.6 }}
          >
            <Box
              sx={{
                marginLeft: { xs: "1rem", md: "auto" },
                marginTop: { xs: "5.5rem", md: "-18.4rem" },
                display: { xs: "none", md: "block" },
              }}
            >
              <img
                src={hero}
                style={{
                  width: "64%",
                  maxWidth: { xs: "200px", md: "400px" },
                }}
                alt=""
              />
            </Box>
          </motion.div>
        </Box>
      </Box>
    </div>
  );
};

export default Header;
