import React from "react";
import Landingpage from "./components/Home/Landingpage";
import NavBar from "./components/Navbar";
import { Fab, useScrollTrigger, Zoom } from "@mui/material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Box } from "@mui/system";

const ScrollTop = ({ children }) => {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      "#back-to-top-anchor"
    );

    if (anchor) {
      anchor.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  };

  return (
    <Zoom in={trigger}>
      <Box
        onClick={handleClick}
        role="presentation"
        sx={{
          position: "fixed",
          bottom: 16,
          right: 16,
        }}
      >
        {children}
      </Box>
    </Zoom>
  );
};

function App() {
  return (
    <div className="App">
      <Box id="back-to-top-anchor" />
      <NavBar />
      <Landingpage />
      <ScrollTop>
        <Fab sx={{bgcolor: "#E0AE73"}} size="small" aria-label="scroll back to top">
          <KeyboardArrowUpIcon sx={{color: 'white'}} />
        </Fab>
      </ScrollTop>
    </div>
  );
}

export default App;
