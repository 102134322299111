import React, { useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import Drawer from "@mui/material/Drawer";
import MenuIcon from "@mui/icons-material/Menu";
import { Slide } from "@mui/material";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import {
  Info,
  MedicalServices,
  Phone,
  RecordVoiceOver,
} from "@mui/icons-material";
import { Button, Tooltip } from "@mui/joy";
import logo from "../images/logo.png";

const pages = [
  {
    label: "Learn more",
    sectionId: "meetShezSection",
    icon: <Info sx={{ color: "#218372" }} />,
  },
  {
    label: "Medicare review",
    sectionId: "whatWeDoSection",
    icon: <MedicalServices sx={{ color: "#218372" }} />,
  },
  {
    label: "What people are saying",
    sectionId: "googleReviewsSection",
    icon: <RecordVoiceOver sx={{ color: "#218372" }} />,
  },
  // { label: "Contact", sectionId: "statsSection", icon: <Contacts /> },
];

function HideOnScroll(props) {
  const { children, window } = props;

  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

function NavBar() {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const handleOpenNavMenu = () => {
    setIsDrawerOpen(true);
  };

  const handleCloseNavMenu = (sectionId) => {
    setIsDrawerOpen(false);
    if (sectionId) {
      document.getElementById(sectionId).scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <HideOnScroll>
      <AppBar style={{ backgroundColor: "#218372" }} position="sticky">
        <Box sx={{ flexGrow: 1 }}>
          <Toolbar disableGutters>
            <Typography
              variant="h6"
              noWrap
              component="a"
              href="/"
              sx={{
                mr: 2,
                display: { xs: "none", md: "flex" },
                fontFamily: "monospace",
                fontWeight: 700,
                letterSpacing: ".1rem",
                color: "inherit",
                textDecoration: "none",
              }}
            >
              <img
                src={logo}
                alt="Logo"
                style={{ marginLeft: "50px", width: "160px" }}
              />
            </Typography>

            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="menu"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>

              <Drawer
                anchor="left"
                open={isDrawerOpen}
                onClose={() => handleCloseNavMenu(null)}
                sx={{ display: { xs: "block", md: "none" } }}
              >
                <Box
                  sx={{ width: 250 }}
                  role="presentation"
                  onClick={() => handleCloseNavMenu(null)}
                  onKeyDown={() => handleCloseNavMenu(null)}
                >
                  {pages.map((page) => (
                    <MenuItem
                      key={page.label}
                      onClick={() => handleCloseNavMenu(page.sectionId)}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          textDecoration: "none",
                          color: "grey",
                          fontWeight: "600",
                          letterSpacing: "1px",
                        }}
                      >
                        {page.icon}
                        <Typography
                          sx={{
                            marginLeft: "8px",
                            textAlign: "center",
                            fontFamily: "Lato, sans-serif",
                            fontSize: "1.1rem",
                          }}
                          component="span"
                        >
                          {page.label}
                        </Typography>
                      </Box>
                    </MenuItem>
                  ))}
                </Box>
              </Drawer>
            </Box>

            <Typography
              variant="h5"
              noWrap
              component="a"
              href="/"
              sx={{
                mr: 2,
                display: { xs: "flex", md: "none" },
                flexGrow: 1,
                fontFamily: "monospace",
                fontWeight: 700,
                letterSpacing: ".3rem",
                color: "inherit",
                textDecoration: "none",
              }}
            >
              <img
                src={logo}
                alt="Logo"
                style={{ marginLeft: "18px", width: "150px" }}
              />
            </Typography>

            <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
              {pages.map((page) => (
                <Button
                  color="inherit"
                  sx={{
                    color: "white",
                    transition: "background-color 0.3s, transform 0.3s",
                    fontFamily: "Lato, sans-serif",
                    fontSize: "1.1rem",
                    "&:hover": {
                      backgroundColor: "rgba(255, 255, 255, 0.2)",
                      transform: "scale(1)",
                    },
                  }}
                  key={page.label}
                  onClick={() => handleCloseNavMenu(page.sectionId)}
                  component="span"
                >
                  {page.label}
                </Button>
              ))}
            </Box>

            <Box sx={{ flexGrow: 0 }}>
              <Box style={{ marginRight: { xs: "2.3rem", md: "5rem" } }}>
                <a href="tel:+16148107479">
                  <IconButton
                    sx={{ marginRight: { xs: "0.2rem", md: "5rem" } }}
                  >
                    <Button
                      startDecorator={<Phone />}
                      color="inherit"
                      sx={{
                        color: "white",
                        transition: "background-color 0.3s, transform 0.3s",
                        fontFamily: "Lato, sans-serif",
                        fontSize: "1.1rem",
                        display: { xs: "none", md: "flex" },
                        "&:hover": {
                          backgroundColor: "rgba(255, 255, 255, 0.2)",
                          transform: "scale(1)",
                        },
                      }}
                      component="span"
                    >
                      (614) 810-7479
                    </Button>
                    <Tooltip
                      title="Call today"
                      placement="left-start"
                      variant="soft"
                    >
                      <Phone
                        sx={{ color: "white", display: { xs: "", md: "none" } }}
                      />
                    </Tooltip>
                  </IconButton>
                </a>
              </Box>
            </Box>
          </Toolbar>
        </Box>
      </AppBar>
    </HideOnScroll>
  );
}

export default NavBar;
