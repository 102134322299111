import React from "react";
import { Typography, Box } from "@mui/joy";
import ScheduleButton from "./ScheduleButton";
import { motion, useInView } from "framer-motion";
import planreview from "../../assets/planreview.mp4";
import videoposter from "../../images/videoposter.png";
const GetStarted = () => {
  const ref = React.useRef(null);
  const isInView = useInView(ref, { once: true });

  return (
    <Box
      sx={{
        padding: "5px",
        marginTop: { xs: "-10.5rem", md: "3.89rem" },
        bgcolor: "#218372",
      }}
    >
      <div style={{ textAlign: "center", marginTop: "3rem" }} ref={ref}>
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: isInView ? 1 : 0, y: isInView ? 0 : -20 }}
          transition={{ duration: 0.5 }}
        >
          <Typography
            level="body-sm"
            sx={{
              letterSpacing: "2px",
              color: "white",
              fontSize: "2rem",
              fontWeight: "800",
              fontFamily: "Lato, sans-serif",
            }}
          >
            Meet Sheizka - Community Relationships Manager
            <Typography
              level="body-sm"
              sx={{
                letterSpacing: "1.5px",
                fontSize: "2rem",
              }}
            ></Typography>
          </Typography>
        </motion.div>
      </div>
      <div
        style={{ textAlign: "center", padding: "10px", letterSpacing: "1px" }}
      >
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: isInView ? 1 : 0, y: isInView ? 0 : 20 }}
          transition={{ duration: 0.5, delay: 0.2 }}
        >
          <Typography
            level="body-sm"
            sx={{
              fontSize: "1rem",
              color: "white",
              fontFamily: "Lato, sans-serif",
            }}
          >
            Let our highly trained specialists help you find the benefits that
            best meet your healthcare needs.
          </Typography>
        </motion.div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          flexWrap: "wrap",
          padding: "10px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            padding: { xs: "20px", md: "40px" },
            width: "100%",
          }}
        >
          <Box sx={{ flex: 1, width: "100%" }}>
            <motion.div
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: isInView ? 1 : 0, scale: isInView ? 1 : 0.8 }}
              transition={{ duration: 0.5, delay: 0.4 }}
            >
              {/* <iframe
                src="https://player.vimeo.com/video/998766535?&amp;autopause=0"
                allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
                style={{
                  width: "100%",
                  height: "350px",
                  border: "none",
                  borderRadius: "8px",
                  // boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                  marginBottom: "20px",
                }}
                title=""
              ></iframe> */}

              <video
                width="100%"
                controls
                poster={videoposter}
                preload="auto"
                playsInline
                style={{
                  marginBottom: "20px",
                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.3)",
                }}
              >
                <source src={planreview} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </motion.div>
          </Box>
          <Box
            sx={{
              flex: 1,
              width: "100%",
              textAlign: "center",
              marginTop: { sx: "1.2rem", md: "2.5rem" },
              letterSpacing: "1px",
              marginLeft: { xs: "", md: "2rem" },
              padding: "5px",
            }}
          >
            <motion.div
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: isInView ? 1 : 0, x: isInView ? 0 : -20 }}
              transition={{ duration: 0.5, delay: 0.6 }}
            >
              <Typography
                level="body-sm"
                sx={{
                  fontSize: "1rem",
                  color: "white",
                  fontFamily: "Lato, sans-serif",
                }}
              >
                Exact Medicare has a team of experienced Medicare specialists
                who work closely with individuals to understand their unique
                needs and preferences. They help clients make informed decisions
                about their healthcare coverage, including Medicare Advantage,
                Medicare Supplement and Part D plans.
                <br />
                <br />
                With their comprehensive knowledge of Medicare and commitment to
                client satisfaction, Exact Medicare is a top choice for those
                seeking guidance in navigating the Medicare system.
              </Typography>
              <div style={{ marginTop: "2rem", marginLeft: "1rem" }}>
                <ScheduleButton />
              </div>
            </motion.div>
          </Box>
        </Box>
      </div>
    </Box>
  );
};

export default GetStarted;
