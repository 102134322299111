import React from "react";
import Header from "./Header";
import MeetShez from "../Home/MeetShez";

import WhatWeDo from "./WhatWeDo";
import GoogleReviews from "./GoogleReviews";
import Partners from "./Partners";
import Footer from "./Footer";

const Landingpage = () => {
  return (
    <div>
      <Header />
      <section id="statsSection">{/* <Stats /> */}</section>
      <section id="meetShezSection">
        <MeetShez />
      </section>
      <section id="whatWeDoSection">
        <WhatWeDo />
      </section>
      <section id="googleReviewsSection">
        <GoogleReviews />
      </section>
      <section>
        <Partners />
      </section>
      <section>
        <Footer />
      </section>
    </div>
  );
};

export default Landingpage;
