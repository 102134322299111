import React, { useState } from "react";
import {
  Box,
  Typography,
  Container,
  Stack,
  IconButton,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  ListItem,
  List,
} from "@mui/material";
import { Facebook, Instagram, LinkedIn } from "@mui/icons-material";
import logo from "../../images/logo.png";

const Footer = () => {
  const [openPrivacy, setOpenPrivacy] = useState(false);
  const [openTerms, setOpenTerms] = useState(false);

  const handleOpenPrivacy = () => setOpenPrivacy(true);
  const handleClosePrivacy = () => setOpenPrivacy(false);

  const handleOpenTerms = () => setOpenTerms(true);
  const handleCloseTerms = () => setOpenTerms(false);

  return (
    <Box
      component="footer"
      sx={{ bgcolor: "#218372", py: 9, marginTop: "4rem" }}
    >
      <Container maxWidth="lg">
        <Stack
          direction={{ xs: "column", sm: "row" }}
          justifyContent="space-between"
          alignItems="center"
          spacing={1}
          sx={{}}
        >
          <Box
            component="img"
            sx={{
              height: 47,
              width: 155,
            }}
            alt="Logo"
            src={logo}
          />
          <Typography
            sx={{
              color: "#CCCCCC",
              fontFamily: "Lato, sans-serif",
              fontSize: "0.9rem",
            }}
          >
            EXPERT GUIDENCE GENUINE CARE
          </Typography>
          {/* <Typography
            sx={{
              color: "#CCCCCC",
              fontFamily: "Lato, sans-serif",
              fontSize: "0.9rem",
            }}
          >
            103 Commerce Park Dr # 103B, Westerville, OH 43082
          </Typography> */}
          {/* <Typography sx={{ color: "#CCCCCC", fontFamily: "Lato, sans-serif" }}>
            (614) 810-7479
          </Typography> */}
          <Button
            onClick={handleOpenPrivacy}
            sx={{ color: "#CCCCCC", fontFamily: "Lato, sans-serif" }}
          >
            Privacy Policy
          </Button>
          <Button
            onClick={handleOpenTerms}
            sx={{ color: "#CCCCCC", fontFamily: "Lato, sans-serif" }}
          >
            Terms & Conditions
          </Button>

          <Stack direction="row" spacing={1}>
            <IconButton
              sx={{ color: "#CCCCCC" }}
              aria-label="facebook"
              href="https://www.facebook.com/callexactmedicare"
            >
              <Facebook />
            </IconButton>

            <IconButton
              sx={{ color: "#CCCCCC" }}
              aria-label="instagram"
              href="https://instagram.com"
            >
              <Instagram />
            </IconButton>
            <IconButton
              sx={{ color: "#CCCCCC" }}
              aria-label="linkedin"
              href="https://www.linkedin.com/company/exactmedicare"
            >
              <LinkedIn />
            </IconButton>
          </Stack>
        </Stack>
      </Container>
      <Box sx={{ padding: "10px", marginTop: "2rem", textAlign: "center" }}>
        <Typography
          level="body-sm"
          sx={{
            fontSize: "1rem",
            color: "white",
            fontFamily: "Lato, sans-serif",
          }}
        >
          We do not offer every plan available in your area. Any information we
          provide is limited to those plans we do offer in your area. Please
          contact Medicare.gov or 1-800-MEDICARE to get information on all of
          your options. Medicare has neither reviewed nor endorsed this
          information. Not connected with or endorsed by the United States
          government or the federal Medicare program.
        </Typography>
      </Box>
      <Box sx={{ textAlign: "center", marginTop: "3rem" }}>
        <Typography
          sx={{
            color: "#CCCCCC",
            fontFamily: "Lato, sans-serif",
            fontSize: "0.9rem",

            padding: "10px",
          }}
        >
           103 Commerce Park Dr # 103B, Westerville, OH 43082
        </Typography>
        <Typography
          sx={{
            fontSize: "1.2rem",
            color: "white",
            fontFamily: "Lato, sans-serif",
          }}
        >
          © Exact Medicare 2024
        </Typography>
      </Box>

      <Dialog open={openPrivacy} onClose={handleClosePrivacy}>
        <DialogTitle>Privacy Policy</DialogTitle>
        <DialogContent>
          <Typography>
            Thank you for choosing Exact Medicare. This Privacy Policy describes
            how Exact Medicare (“we,” “our,” or “us”) collects, uses, shares,
            and protects information obtained from users (“you” or “your”) of
            our website, mobile application, products, and services
            (collectively, the “Services”). By accessing or using our Services,
            you agree to the terms of this Privacy Policy.
          </Typography>
          <List>
            <ListItem sx={{ fontWeight: "bold" }}>
              1. Information We Collect:
            </ListItem>
            <ListItem>
              a. Personal Information: We may collect personal information that
              you provide to us voluntarily, such as your name, address, email
              address, phone number, date of birth, and other similar
              information.
            </ListItem>
            <ListItem>
              b. Payment Information: If you make purchases through our
              Services, we may collect payment information, such as credit card
              details or other financial information.
            </ListItem>
            <ListItem>
              c. Usage Information: We may collect information about how you
              interact with our Services, including your IP address, browser
              type, operating system, device information, and usage patterns.
            </ListItem>
            <ListItem>
              d. Cookies and Tracking Technologies: We may use cookies and
              similar tracking technologies to collect information about your
              preferences and browsing activities. You can manage your cookie
              preferences through your browser settings.
            </ListItem>
            <ListItem sx={{ fontWeight: "bold" }}>
              2. How We Use Your Information:
            </ListItem>
            <ListItem>
              a. To Provide Services: We use the information we collect to
              provide and personalize our Services, process transactions,
              communicate with you, and fulfill your requests.
            </ListItem>
            <ListItem>
              b. To Improve Services: We may use your information to analyze
              trends, monitor the effectiveness of our Services, and improve the
              user experience.
            </ListItem>
            <ListItem>
              c. For Marketing Purposes: With your consent, we may use your
              information to send promotional emails, newsletters, and other
              marketing communications.
            </ListItem>
            <ListItem>
              d. Legal Compliance: We may use your information to comply with
              legal obligations, resolve disputes, and enforce our agreements.
            </ListItem>
            <ListItem sx={{ fontWeight: "bold" }}>
              3. Information Sharing:
            </ListItem>
            <ListItem>
              a. Service Providers: We may share your information with
              third-party service providers who help us operate our business,
              such as payment processors, hosting providers, and marketing
              partners.
            </ListItem>
            <ListItem>
              b. Legal Requirements: We may disclose your information when
              required by law or in response to valid legal requests, such as
              court orders or subpoenas.
            </ListItem>
            <ListItem>
              c. Business Transfers: In the event of a merger, acquisition, or
              sale of assets, your information may be transferred to another
              entity as part of the transaction.
            </ListItem>
            <ListItem>
              d. Consent: We may share your information with your consent or at
              your direction.
            </ListItem>
            <ListItem sx={{ fontWeight: "bold" }}>4. Data Security:</ListItem>
            <ListItem>
              We implement reasonable security measures to protect your
              information from unauthorized access, alteration, disclosure, or
              destruction. However, please be aware that no method of
              transmission over the internet or electronic storage is 100%
              secure.
            </ListItem>
            <ListItem sx={{ fontWeight: "bold" }}>5. Your Choices:</ListItem>
            <ListItem>
              a. Opt-Out: You may opt-out of receiving marketing communications
              from us by following the unsubscribe instructions provided in our
              emails or by contacting us directly at (380)-242-2006.
            </ListItem>
            <ListItem>
              b. Cookie Preferences: You can manage your cookie preferences
              through your browser settings.
            </ListItem>
            <ListItem sx={{ fontWeight: "bold" }}>
              6. Children’s Privacy:
            </ListItem>
            <ListItem>
              Our Services are not intended for individuals under the age of 18.
              We do not knowingly collect personal information from children
              without parental consent. If you believe that we have
              inadvertently collected information from a child, please contact
              us immediately. We only collect information voluntarily by those
              ages 65+.
            </ListItem>
            <ListItem sx={{ fontWeight: "bold" }}>
              7. Changes to this Privacy Policy:
            </ListItem>
            <ListItem>
              We reserve the right to update or modify this Privacy Policy at
              any time. We will notify you of any material changes by posting
              the updated policy on our website or through other means of
              communication.
            </ListItem>
            <ListItem sx={{ fontWeight: "bold" }}>8. Contact Us:</ListItem>
            <ListItem>
              If you have any questions or concerns about this Privacy Policy or
              our data practices, please contact us at (380)-242-2006. By using
              our Services, you consent to the collection, use, and sharing of
              your information as described in this Privacy Policy.
            </ListItem>
          </List>
        </DialogContent>
        <DialogActions>
          <Button sx={{ color: "#218372" }} onClick={handleClosePrivacy}>
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openTerms} onClose={handleCloseTerms}>
        <DialogTitle>Terms & Conditions</DialogTitle>
        <DialogContent>
          <Typography>
            These terms of service outline the rules and regulations for the use
            of Exact Medicare’s Website. By accessing this website we assume you
            accept these terms of service in full. Do not continue to use Exact
            Medicare’s website if you do not accept all of the terms of service
            stated on this page. The following terminology applies to these
            Terms of Service, Privacy Statement and Disclaimer Notice and any or
            all Agreements: “Client”, “You” and “Your” refers to you, the person
            accessing this website and accepting the Company’s terms of service.
            “The Company”, “Ourselves”, “We”, “Our” and “Us”, refers to our
            Company. “Party”, “Parties”, or “Us”, refers to both the Client and
            ourselves, or either the Client or ourselves. All terms refer to the
            offer, acceptance and consideration of payment necessary to
            undertake the process of our assistance to the Client in the most
            appropriate manner, whether by formal meetings of a fixed duration,
            or any other means, for the express purpose of meeting the Client’s
            needs in respect of provision of the Company’s stated
            services/products, in accordance with and subject to, prevailing law
            of . Any use of the above terminology or other words in the
            singular, plural, capitalisation and/or he/she or they, are taken as
            interchangeable and therefore as referring to same.
          </Typography>
          <List>
            <ListItem sx={{ fontWeight: "bold" }}>Cookies</ListItem>
            <ListItem>
              We employ the use of cookies. By using Exact Medicare’s website
              you consent to the use of cookies in accordance with Exact
              Medicare’s privacy policy. Most of the modern day interactive web
              sites use cookies to enable us to retrieve user details for each
              visit. Cookies are used in some areas of our site to enable the
              functionality of this area and ease of use for those people
              visiting. Some of our affiliate / advertising partners may also
              use cookies.
            </ListItem>
            <ListItem sx={{ fontWeight: "bold" }}>License</ListItem>
            <ListItem>
              Unless otherwise stated, Exact Medicare and/or it’s licensors own
              the intellectual property rights for all material on Exact
              Medicare. All intellectual property rights are reserved. You may
              view and/or print pages from exactmedicare.com for your own
              personal use subject to restrictions set in these terms of
              service.
            </ListItem>
            <ListItem>You must not:</ListItem>
            <ListItem>1. Republish material from exactmedicare.com</ListItem>
            <ListItem>
              2. Sell, rent or sub-license material from exactmedicare.com{" "}
            </ListItem>
            <ListItem>
              3.Reproduce, duplicate or copy material from exactmedicare.com{" "}
            </ListItem>
            <ListItem>
              Redistribute content from Exact Medicare (unless content is
              specifically made for redistribution).
            </ListItem>

            <ListItem sx={{ fontWeight: "bold" }}>Content Liability</ListItem>

            <ListItem>
              We shall have no responsibility or liability for any content
              appearing on your Web site. You agree to indemnify and defend us
              against all claims arising out of or based upon your Website. No
              link(s) may appear on any page on your Web site or within any
              context containing content or materials that may be interpreted as
              libelous, obscene or criminal, or which infringes, otherwise
              violates, or advocates the infringement or other violation of, any
              third party rights.
            </ListItem>
            <ListItem sx={{ fontWeight: "bold" }}>
              Reservation of Rights
            </ListItem>
            <ListItem>
              We reserve the right at any time and in its sole discretion to
              request that you remove all links or any particular link to our
              Web site. You agree to immediately remove all links to our Web
              site upon such request. We also reserve the right to amend these
              terms of service and its linking policy at any time. By continuing
              to link to our Web site, you agree to be bound to and abide by
              these linking terms of service.
            </ListItem>
          </List>
          <ListItem sx={{ fontWeight: "bold" }}>
            Removal of links from our website
          </ListItem>
          <ListItem>
            If you find any link on our Web site or any linked web site
            objectionable for any reason, you may contact us about this. We will
            consider requests to remove links but will have no obligation to do
            so or to respond directly to you. Whilst we endeavour to ensure that
            the information on this website is correct, we do not warrant its
            completeness or accuracy; nor do we commit to ensuring that the
            website remains available or that the material on the website is
            kept up to date.
          </ListItem>
          <ListItem sx={{ fontWeight: "bold" }}>Disclaimer</ListItem>
          <ListItem>
            To the maximum extent permitted by applicable law, we exclude all
            representations, warranties and conditions relating to our website
            and the use of this website (including, without limitation, any
            warranties implied by law in respect of satisfactory quality,
            fitness for purpose and/or the use of reasonable care and skill).
            Nothing in this disclaimer will:
          </ListItem>
          <ListItem>
            1. limit or exclude our or your liability for death or personal
            injury resulting from negligence;
          </ListItem>
          <ListItem>
            2.limit or exclude our or your liability for fraud or fraudulent
            misrepresentation;
          </ListItem>
          <ListItem>
            3. limit any of our or your liabilities in any way that is not
            permitted under applicable law; or
          </ListItem>
          <ListItem>
            4. exclude any of our or your liabilities that may not be excluded
            under applicable law.
          </ListItem>
        </DialogContent>
        <DialogActions>
          <Button sx={{ color: "#218372" }} onClick={handleCloseTerms}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Footer;
