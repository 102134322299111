import { AddCircle } from "@mui/icons-material";
import { Box, List, ListItem, ListItemDecorator, Typography } from "@mui/joy";

import React from "react";

const HeaderList = () => {
  const listItems = [
    {
      id: 1,
      icon: <AddCircle sx={{ color: "#218372", fontSize: 28 }} />,
      desc: "More than 2,000 five-star reviews on Google",
    },

    {
      id: 2,
      icon: <AddCircle sx={{ color: "#218372", fontSize: 28 }} />,
      desc: "Trusted by more than 60,000 retirees",
    },
    {
      id: 3,
      icon: <AddCircle sx={{ color: "#218372", fontSize: 28 }} />,
      desc: "One of the fastest growing agencies in the U.S. ",
    },
  ];

  return (
    <div>
      <Box>
        <Typography sx={{ fontSize: "1.2rem", fontWeight: "bold" }}>
          Join thousands of satisfied clients that trust us for their Medicare
          needs
        </Typography>

        <Box>
          <List>
            {listItems.map((item) => (
              <ListItem sx={{ fontWeight: "bold" }} key={item.id}>
                <ListItemDecorator> {item.icon} </ListItemDecorator>
                {item.desc}
              </ListItem>
            ))}
          </List>
        </Box>
      </Box>
    </div>
  );
};

export default HeaderList;
